<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="getSMStransactions">
      <div class="row">
        <div class="col-sm-6 col-md-3" v-if="isAdmin(roleId)">
          transactionId:
          <br />
          <b-form-input v-model="searchData.transactionId"></b-form-input>
        </div>
        <div class="col-sm-6 col-md-3">
          {{ $t('message.machineIdFarm') }}:
          <br />
          <list-select
            :list="machineData"
            :selected-item="selectedMachine"
            placeholder="Select Machine ID"
            option-value="machineId"
            option-text="name"
            @select="onSelect"
            required
          />
        </div>
        <div class="col-sm-6 col-md-3">
          {{ $t('message.refNumber') }}:
          <br />
          <b-form-input v-model="searchData.refNumber"></b-form-input>
        </div>
        <div class="col-sm-6 col-md-2">
          {{ $t('message.date') }} :
          <br />
          <date-picker type="datetime" v-model="datetime" lang="en" range format="yyyy-MM-dd"></date-picker>
        </div>
        <div class="col-sm-6 col-md-2">
          <br />
          <b-btn variant="success" type="submit">
            <i class="fa fa-search"></i>
            &nbsp;{{ $t('message.search') }}
          </b-btn>
        </div>
      </div>
    </b-form>
    <br />
    <b-card>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align-last: center">
            <th v-if="isAdmin(roleId)">transactionId</th>
            <th v-if="isAdmin(roleId)">transactionSMS</th>
            <th>{{ $t('message.machineIdFarm') }}</th>
            <th>{{ $t('message.refNumber') }}</th>
            <th>{{ $t('message.status') }}</th>
            <th>{{ $t('message.details') }}</th>
            <th>{{ $t('message.statusSending') }}</th>
            <th style="text-align-last: right">{{ $t('message.creditUsed') }}</th>
            <th
              style="text-align-last: right"
              v-if="isAdmin(roleId)"
            >{{ $t('message.creditBalance') }}</th>
            <th>{{ $t('message.createdAt') }}</th>
            <th>{{ $t('message.updatedAt') }}</th>
          </thead>
          <tbody>
            <template v-for="items in rowData">
              <tr style="text-align: center">
                <td v-if="isAdmin(roleId)">{{items.transactionId}}</td>
                <td v-if="isAdmin(roleId)">{{items.transactionSMS}}</td>
                <td>{{items.machineId}}</td>
                <td>{{items.refNumber}}</td>
                <td>
                  <template v-if="items.status === 1">
                    <span
                      class="badge badge-success"
                      style="font-size: 100%"
                    >{{ $t('message.success') }}</span>
                  </template>
                  <template v-else>
                    <span
                      class="badge badge-danger"
                      style="font-size: 100%"
                    >{{ $t('message.fail') }}</span>
                  </template>
                </td>
                <td>{{items.details}}</td>
                <td>
                  <template v-if="items.smsStatus === 'Delivered'">
                    <span
                      class="badge badge-primary"
                      style="font-size: 100%"
                    >{{ $t('message.customerReceive') }}</span>
                  </template>
                  <template v-else>
                    <span class="badge badge-danger" style="font-size: 100%">{{items.smsStatus}}</span>
                  </template>
                </td>
                <td style="text-align: right">{{items.usedcredit}}</td>
                <td style="text-align: right" v-if="isAdmin(roleId)">{{items.remaincredit}}</td>
                <td>{{items.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                <td>{{items.updatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <br />
      <b-pagination
        size="md"
        :total-rows="totalRows"
        v-model="pagination"
        :per-page="itemperPage"
        @input="pageChanged(pagination)"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import webServices from "../../script";
import moment from "moment";
import DatePicker from "../../custom_modules/vue2-datepicker";
import { ListSelect } from "../../custom_modules/search-select";
export default {
  name: "smsreport",
  components: {
    DatePicker,
    ListSelect
  },
  data() {
    return {
      roleId: window.localStorage.getItem("roleID"),
      datetime: [
        moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss")
      ],
      searchData: {
        page: 1,
        rows: 30
      },
      rowData: [],
      totalRows: 1,
      pagination: 1,
      itemperPage: 30,
      machineData: [],
      selectedMachine: {}
    };
  },
  methods: {
    getSMStransactions() {
      this.$Progress.start();
      var timeEnd = moment(this.datetime[1]).format("HH:mm:ss");
      if (timeEnd === "00:00:00" || timeEnd === "12:00:00 AM") {
        this.datetime[1].setHours(23, 59, 59, 999);
      }
      this.searchData.from = moment(this.datetime[0]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.searchData.to = moment(this.datetime[1]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      webServices
        .smsTransactions(this.searchData)
        .then(res => {
          this.$Progress.finish();
          console.log(res.data);
          this.rowData = res.data.rows;
          this.totalRows = res.data.count;
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
        });
    },
    pageChanged(PageNum) {
      this.searchData.page = PageNum;
      this.getSMStransactions();
    },
    onSelect(item) {
      this.selectedMachine = item;
      this.searchData.machineId = item.machineId;
    },
    isAdmin(role) {
      return webServices.isAdmin(role);
    },
    getMachine() {
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.machineData = res.data;
          // console.log(this.machineData)
        })
        .catch(err => {
          console.log(err);
          console.log("error @MACHINE");
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    }
  },
  mounted() {
    this.getSMStransactions();
    this.getMachine();
  }
};
</script>